import React from 'react';
import classes from "./Advantage.module.css"
import img1 from "./../../Assest/cm.png"
import img2 from "./../../Assest/house.png"
import img3 from "./../../Assest/solution.png"
import img4 from "./../../Assest/partners.png"
import img5 from "./../../Assest/collaborate.png"
import img6 from "./../../Assest/quality.png"
import img7 from "./../../Assest/high-quality.png"
import img8 from "./../../Assest/medal-.png"
import Fade from 'react-reveal/Fade';


const Advantage = () => {
  return (
    <div>

          <h2 className={classes.ih3h}>Advantages</h2>

<div className={classes.Adv}>

<div className={classes.Adv1}>
  <Fade left>
  <div className={classes.set}> <h5 className={classes.set1}>Founded by Industry Experts</h5> <img src={img1} className={classes.seti}/></div>
    <div className={classes.set}> <h5 className={classes.set1}>End To End Solution Provider</h5> <img src={img3} className={classes.seti}/></div>
     <div className={classes.set}> <h5 className={classes.set1}>Trusted Technology Partners</h5> <img src={img4} className={classes.seti}/></div>
</Fade>

</div>

<div className={classes.Adv2}>
   <Fade right>
   <div className={classes.set}> <img src={img6} className={classes.seti}/> <h5 className={classes.set1}>2+ Years Experience</h5> </div>
    <div className={classes.set}> <img src={img5} className={classes.seti}/> <h5 className={classes.set1}>Direct Client Collaboration</h5> </div>
     <div className={classes.set}> <img src={img7}className={classes.seti}/> <h5 className={classes.set1}>High Quality Product</h5> </div>
</Fade>
</div>



</div>


    </div>
  )
}

export default Advantage