import React from "react";
import Slider from "react-slick";
import Rcard from "./Testimonialprops";
import Boy from "./../../Assest/boy.png";
import Girl from "./../../Assest/girl.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "./Testimonials.module.css";
/*
import t1 from "./../../Assests/golde.png";
import t2 from "./../../Assests/siresj.png";
import t3 from "./../../Assests/dipika.png";
import t4 from "./../../Assests/p.png";

*/
const Testimonial = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                speed: 8000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false  
              }
            }
        ]
    };

    return (
<div> 

<div className={classes.rsl}>
      
    <h2 className={classes.ih3h}>Testimonials</h2>
<div className={classes.rsl2}>      
                <Slider {...settings}>

<div> {/*imgg={t1} */}
<Rcard imgg={Boy}  name='Bipin Singh' review='Best Workplace for Web Development. Best to Learn new technologies' ></Rcard>
</div>
<div>
<Rcard imgg={Girl} name='Pradnya Pethkar' review='Thank you for always quick supports regarding my sites chanes and modifications.' ></Rcard>
</div>
<div>
<Rcard imgg={Boy} name='Mukesh Singh' review='Amazing services… kudos to you …👏👏👏creativity on the top - Mukesh' ></Rcard>
</div>
<div>
<Rcard imgg={Girl} name='Namita Kulkarni' review='Thank you Mrs. Anamikaji to resolve my website solution with this much professional and creative work.' ></Rcard>
</div>
<div>
<Rcard imgg={Boy} name='Ajinkya Kawade' review='They listened to all our queries and made my website stand out on interner just perfectly.' ></Rcard>
</div>






                </Slider>
                </div>
       </div>     
   </div>    
    );
}

export default Testimonial;
