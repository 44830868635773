import React from 'react'
import classes from "./eleg.module.css";
import Fade from "react-reveal/Fade"

import a from './../../Assest/j.png';
import b from './../../Assest/k.png';
import c from './../../Assest/d.png';
import d from './../../Assest/a.png';

const eleg = () => {
  return (
    <div>

<div className={classes.steps}>

<div className={classes.steps0}><Fade left >
<div className={classes.d1}>
  <h2 className={classes.ih4}>EshikaTech Pvt Ltd</h2>
  <p className={classes.ih41}>EshikaTech Pvt. Ltd. is a premier web development company committed to delivering cutting-edge digital solutions. We specialize in designing and developing custom websites, web applications, and e-commerce platforms that not only look stunning but also function seamlessly. Our team of skilled developers and designers works closely with clients to create responsive, user-friendly, and secure web solutions tailored to their unique needs. With a focus on innovation, quality, and reliability, we ensure that every project we undertake enhances our clients' online presence and drives business success. At EshikaTech Pvt. Ltd., we transform ideas into engaging digital experiences.</p>
</div>
</Fade>
<Fade right >
<div className={classes.d2}> <a href="tel:9967190248"><button className={classes.b1}> Call Us </button></a></div></Fade>
</div>


<div className={classes.steps1}>
  <Fade left >
<div className={classes.st1}>
  <img src={a} className={classes.imgs} />
  <h5 className={classes.ih3}>Affordable Web Solutions</h5>
  <h6 className={classes.ih31}>
    We offer cost-effective web development services that deliver exceptional value without compromising quality.
  </h6>
</div></Fade>
  <Fade left >
<div className={classes.st1}>
  <img src={b} className={classes.imgs} />
  <h5 className={classes.ih3}>Tailored Designs</h5>
  <h6 className={classes.ih31}>
    Our team crafts custom web designs that reflect your brand's unique identity and resonate with your target audience.
  </h6>
</div>
</Fade>
  <Fade right >
<div className={classes.st1}>
  <img src={c} className={classes.imgs} />
  <h5 className={classes.ih3}>On-Time Project Delivery</h5>
  <h6 className={classes.ih31}>
    We are committed to delivering projects on schedule, ensuring your website is launched as planned.
  </h6>
</div></Fade>
<Fade right >
<div className={classes.st1}>
  <img src={d} className={classes.imgs} />
  <h5 className={classes.ih3}>24/7 Technical Support</h5>
  <h6 className={classes.ih31}>
    Our dedicated support team is available around the clock to assist you with any issues or questions you may have.
  </h6>
</div></Fade>
</div>



</div>

    </div>
  )
}

export default eleg