import React, { useEffect } from 'react';
import Servicess from "./../Component/Service/Services";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Services Page Meta Tags
    document.title = 'Eshikatech: Our Services | Website Design & Development, Domain & Hosting, CMS';

    // Set meta description dynamically
    const servicesMetaDescription = document.createElement('meta');
    servicesMetaDescription.name = 'description';
    servicesMetaDescription.content = 'Explore the range of services offered by Eshikatech, including website design and development, domain and hosting, and content management systems.';
    document.head.appendChild(servicesMetaDescription);

    // Set meta keywords dynamically
    const servicesMetaKeywords = document.createElement('meta');
    servicesMetaKeywords.name = 'keywords';
    servicesMetaKeywords.content = 'Eshikatech, Services, Website Design, Web Development, Domain and Hosting, Content Management Systems, IT Services';
    document.head.appendChild(servicesMetaKeywords);

    // Set canonical link dynamically
    const servicesCanonicalLink = document.createElement('link');
    servicesCanonicalLink.rel = 'canonical';
    servicesCanonicalLink.href = 'https://eshikatech.com/services';
    document.head.appendChild(servicesCanonicalLink);

    // Set Open Graph title dynamically
    const servicesOgTitle = document.createElement('meta');
    servicesOgTitle.property = 'og:title';
    servicesOgTitle.content = 'Eshikatech: Our Services | Website Design & Development, Domain & Hosting, CMS';
    document.head.appendChild(servicesOgTitle);

    // Set Open Graph description dynamically
    const servicesOgDescription = document.createElement('meta');
    servicesOgDescription.property = 'og:description';
    servicesOgDescription.content = 'Explore the range of services offered by Eshikatech, including website design and development, domain and hosting, and content management systems.';
    document.head.appendChild(servicesOgDescription);

    // Set Open Graph URL dynamically
    const servicesOgUrl = document.createElement('meta');
    servicesOgUrl.property = 'og:url';
    servicesOgUrl.content = 'https://eshikatech.com/services';
    document.head.appendChild(servicesOgUrl);

    // Set Open Graph site name dynamically
    const servicesOgSiteName = document.createElement('meta');
    servicesOgSiteName.property = 'og:site_name';
    servicesOgSiteName.content = 'Eshikatech Pvt Ltd';
    document.head.appendChild(servicesOgSiteName);

    // Set Open Graph image dynamically
    const servicesOgImage = document.createElement('meta');
    servicesOgImage.property = 'og:image';
    servicesOgImage.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(servicesOgImage);

    // Set Open Graph secure image URL dynamically
    const servicesOgImageSecure = document.createElement('meta');
    servicesOgImageSecure.property = 'og:image:secure_url';
    servicesOgImageSecure.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(servicesOgImageSecure);

    // Set Open Graph image width dynamically
    const servicesOgImageWidth = document.createElement('meta');
    servicesOgImageWidth.property = 'og:image:width';
    servicesOgImageWidth.content = '300';
    document.head.appendChild(servicesOgImageWidth);

    // Set Open Graph image height dynamically
    const servicesOgImageHeight = document.createElement('meta');
    servicesOgImageHeight.property = 'og:image:height';
    servicesOgImageHeight.content = '200';
    document.head.appendChild(servicesOgImageHeight);

    // Add Structured Data for Services Page
    const servicesStructuredData = document.createElement('script');
    servicesStructuredData.type = 'application/ld+json';
    servicesStructuredData.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Service",
      "serviceType": "Website Design & Development, Domain & Hosting, Content Management System",
      "provider": {
        "@type": "Organization",
        "name": "Eshikatech Pvt Ltd",
        "url": "https://eshikatech.com",
        "logo": "https://eshikatech.com/lo.jpeg"
      },
      "areaServed": {
        "@type": "Place",
        "name": "Global"
      },
      "description": "Eshikatech offers a range of services including website design and development, domain and hosting, and content management systems to help businesses thrive online."
    });
    document.head.appendChild(servicesStructuredData);

    // Cleanup on component unmount
    return () => {
      document.title = 'Eshikatech: Our Services | Website Design & Development, Domain & Hosting, CMS';
      document.head.removeChild(servicesMetaDescription);
      document.head.removeChild(servicesMetaKeywords);
      document.head.removeChild(servicesCanonicalLink);
      document.head.removeChild(servicesOgTitle);
      document.head.removeChild(servicesOgDescription);
      document.head.removeChild(servicesOgUrl);
      document.head.removeChild(servicesOgSiteName);
      document.head.removeChild(servicesOgImage);
      document.head.removeChild(servicesOgImageSecure);
      document.head.removeChild(servicesOgImageWidth);
      document.head.removeChild(servicesOgImageHeight);
      document.head.removeChild(servicesStructuredData);
    };

  }, []);

  return (
    <div><Servicess /></div>
  );
}

export default Services;
