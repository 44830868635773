import React from "react";
import {Link} from "react-router-dom"
import classes from "./Service.module.css";
import hb from "./../../Assest/j.png"
import hb2 from "./../../Assest/l.png"
import hb3 from "./../../Assest/f.png"
import Fade from 'react-reveal/Fade';
const Service =() =>{



    return(
<>
<div  className={classes.block}>

<div className={classes.rect}>
    <Fade left>
<div className={classes.rect1}>
    <img src={hb}  className={classes.im1} />
    
</div>
<div className={classes.rect2}>
<h3  className={classes.ih4}>Website Design & Development</h3>
<p  className={classes.ih41}>Our Team is constantly pushing the boundaries of current thinking to deliver cutting-edge user experiences and user interfaces as an exceptional web design business.</p>
<Link to='/contact'  className={classes.b22}><button   className={classes.b2}>Contact Us</button></Link>

</div>
</Fade>
</div>


<div className={classes.rect}>
        <Fade right>
    <div className={classes.rect2}>
<h3  className={classes.ih4}>Domain & Hosting</h3>
<p  className={classes.ih41}>A domain and hosting service gives you your business website with an online presence and storage.</p>
<Link to='/contact'  className={classes.b22}><button className={classes.b2}>Contact Us</button></Link>
</div>
<div className={classes.rect1}>
    <img src={hb2}  className={classes.im2} />
</div>
</Fade>
</div>


<div className={classes.rect}>
    <Fade left>
<div className={classes.rect1}>
    <img src={hb3}  className={classes.im1} />
</div>
<div className={classes.rect2}>
<h3  className={classes.ih4}>Content Management System</h3>
<p  className={classes.ih41}>Our content management service offers a comprehensive solution for businesses and individuals to efficiently manage their digital content with help of wordpress.</p>
<Link to='/contact'  className={classes.b22}><button   className={classes.b2}>Contact Us</button></Link>
</div>
</Fade>
</div>
</div>

</>
    )
}

export default Service; 