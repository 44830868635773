import React from 'react'
import { useRef } from 'react';
import classes from "./Footer.module.css"
import logo2 from './../../Assest/lo.png';
import Heart from "./../../Assest/eziigj.gif"
import Fade from 'react-reveal/Fade';

const Foot = () => {

const currentyear=new Date().getFullYear();
  return (
    <div className={classes.footer}>
        <div className={classes.footer3}>
<center className={classes.klk}><hr className={classes.hr2}></hr></center>
</div>


<div className={classes.footer1}>

     <div className={classes.in}>
    
    <div>
<img src={logo2} className={classes.fi}/>

<p className={classes.fp}>At EshikaTech Pvt. Ltd., we are dedicated to transforming innovative ideas into cutting-edge solutions. </p>
    </div>


</div>   


<div className={classes.cd}>
    
    <div>
<p className={classes.fh5}>Email :</p>
<a className={classes.fp2} href="mailto:info@eshikatech.com">info@eshikatech.com</a>  
    </div>

    <div>
<p className={classes.fh5}>Phone :</p>
<a className={classes.fp2} href="tel:9967190248">+91 9967190248
 </a>  
    </div>

    <div>
<p className={classes.fh5}>Address :</p>
<a className={classes.fp2}>Kandiwali East, Mumbai, 400101</a>  
    </div>




</div>



<div className={classes.ql}>
    <p className={classes.fh5}>Quick Links</p>
<div  className={classes.hu}>
    <li><a href="/ " className={classes.apal}>Home</a></li>
    <li><a href="/about" className={classes.apal}>About</a></li>
    <li><a href="/service" className={classes.apal}>Services</a></li>
    <li><a href="/contact" className={classes.apal}>Contact</a></li>
</div>
</div>

<br />
<div className={classes.ap}> 

<p className={classes.fh5}>Enquire Here</p>


  <form action="https://formsubmit.co/info@shikatech.com" method="POST">

<input placeholder='ENTER YOUR NAME' className={classes.I1} name='name' id='name' type="text"></input><br />
<input placeholder='ENTER MOBILE NO' maxLength={10} className={classes.I1} name='phn' id='phn' type="text"></input><br />
<input placeholder='ENTER YOUR EMAIL ID' className={classes.I1} name='mail' id='mail' type="email"></input><br />
<textarea placeholder='DESCRIPTION' rows="4" className={classes.t1} name='des' id='des'></textarea><br />
<input type="submit" value="SUBMIT" />
</form>


</div>
</div>

<div className={classes.footer2}>
<center className={classes.klk}>Copyright © {currentyear} | Made with <img src={Heart} className={classes.hrt}></img> by EeshikaTech Pvt Ltd</center>
</div>

    </div>
  )
}

export default Foot