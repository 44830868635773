import React from "react";
import ImageToParticle from "./Big";
import K from "./../../Assest/lo.png"
import classes from "./Big.module.css"

function App() {
  return (
    <div className={classes.dbd1}><center>
      {/* Provide the path to the image as the `path` prop */}
      <ImageToParticle path={K} width={900} height={900} particleSize={1} numParticles={60000} className={classes.dbd}/>
    </center>
    </div>
  );
}

export default App;