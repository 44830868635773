import classes from './ourclientsprops.module.css';
import { Link } from 'react-router-dom';

const ourclientsprops = (props) => {
    return <div className={classes.contain}>
      <center>
            <div className={classes.imgHold}>
                <img src={props.img} className={classes.img}/>
            </div>
            </center>
        </div>
};

export default ourclientsprops;