import React, { useEffect } from 'react';
import Slider from "./../Component/Home/slider";
import Big from "./../Component/Home/Big1";
import Box from "./../Component/Home/box";
import Advantage from "./../Component/Home/Advantage";
import Benifits from "./../Component/Home/Benifits";
import Testimonials from "./../Component/Home/Testimonials";
import Elegent from "./../Component/Home/eleg";
import Pfe from "./../Component/Home/Pfe";
import Clients from "./../Component/Home/ourclients";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

    // Home Page Meta Tags
    document.title = 'Eshikatech: Innovative Tech Solutions | Home';

    // Set meta description dynamically
    const homeMetaDescription = document.createElement('meta');
    homeMetaDescription.name = 'description';
    homeMetaDescription.content = 'Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.';
    document.head.appendChild(homeMetaDescription);

    // Set meta keywords dynamically
    const homeMetaKeywords = document.createElement('meta');
    homeMetaKeywords.name = 'keywords';
    homeMetaKeywords.content = 'Eshikatech, Tech Solutions, Innovative Technology, Business Tech Services, IT Solutions, Technology Services';
    document.head.appendChild(homeMetaKeywords);

    // Set canonical link dynamically
    const homeCanonicalLink = document.createElement('link');
    homeCanonicalLink.rel = 'canonical';
    homeCanonicalLink.href = 'https://eshikatech.com';
    document.head.appendChild(homeCanonicalLink);

    // Set Open Graph title dynamically
    const homeOgTitle = document.createElement('meta');
    homeOgTitle.property = 'og:title';
    homeOgTitle.content = 'Eshikatech: Innovative Tech Solutions | Home';
    document.head.appendChild(homeOgTitle);

    // Set Open Graph description dynamically
    const homeOgDescription = document.createElement('meta');
    homeOgDescription.property = 'og:description';
    homeOgDescription.content = 'Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.';
    document.head.appendChild(homeOgDescription);

    // Set Open Graph URL dynamically
    const homeOgUrl = document.createElement('meta');
    homeOgUrl.property = 'og:url';
    homeOgUrl.content = 'https://eshikatech.com';
    document.head.appendChild(homeOgUrl);

    // Set Open Graph site name dynamically
    const homeOgSiteName = document.createElement('meta');
    homeOgSiteName.property = 'og:site_name';
    homeOgSiteName.content = 'Eshikatech Pvt Ltd';
    document.head.appendChild(homeOgSiteName);

    // Set Open Graph image dynamically
    const homeOgImage = document.createElement('meta');
    homeOgImage.property = 'og:image';
    homeOgImage.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(homeOgImage);

    // Set Open Graph secure image URL dynamically
    const homeOgImageSecure = document.createElement('meta');
    homeOgImageSecure.property = 'og:image:secure_url';
    homeOgImageSecure.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(homeOgImageSecure);

    // Set Open Graph image width dynamically
    const homeOgImageWidth = document.createElement('meta');
    homeOgImageWidth.property = 'og:image:width';
    homeOgImageWidth.content = '300';
    document.head.appendChild(homeOgImageWidth);

    // Set Open Graph image height dynamically
    const homeOgImageHeight = document.createElement('meta');
    homeOgImageHeight.property = 'og:image:height';
    homeOgImageHeight.content = '200';
    document.head.appendChild(homeOgImageHeight);

    // Add Structured Data for Home Page
    const homeStructuredData = document.createElement('script');
    homeStructuredData.type = 'application/ld+json';
    homeStructuredData.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Eshikatech Pvt Ltd",
      "url": "https://eshikatech.com",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://eshikatech.com/search?query={search_term_string}",
        "query-input": "required name=search_term_string"
      },
      "description": "Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.",
      "inLanguage": "en"
    });
    document.head.appendChild(homeStructuredData);

    // Cleanup on component unmount
    return () => {
      document.title = 'Eshikatech: Innovative Tech Solutions | Home';
      document.head.removeChild(homeMetaDescription);
      document.head.removeChild(homeMetaKeywords);
      document.head.removeChild(homeCanonicalLink);
      document.head.removeChild(homeOgTitle);
      document.head.removeChild(homeOgDescription);
      document.head.removeChild(homeOgUrl);
      document.head.removeChild(homeOgSiteName);
      document.head.removeChild(homeOgImage);
      document.head.removeChild(homeOgImageSecure);
      document.head.removeChild(homeOgImageWidth);
      document.head.removeChild(homeOgImageHeight);
      document.head.removeChild(homeStructuredData);
    };

  }, []);

  return (
    <div>
      {/* <Big></Big>  */}
      <Pfe />
      <Elegent />
      <Box />
      <Advantage />
      <Benifits />
      <Testimonials />
      <Clients />
    </div>
  );
}

export default Home;
