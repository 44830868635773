import React from "react"
import classes from "./Ab2.module.css"
import a1 from "./../../Assest/n.png";
import a2 from "./../../Assest/m.png";
import Fade from 'react-reveal/Fade';

const Ab2 = () => {
    return (
        
    <>


<div className={classes.a1}>
 <Fade left>
<div  className={classes.a12}>
        <img src={a2} className={classes.img} />
</div>
</Fade>
<div className={classes.a11}>
    <Fade right>
<h3 className={classes.ih4}>Our Vision</h3>
<p className={classes.ih41}>To be the best option for anyone looking for a Static and Dynamic Websites in Mumbai and Mumbai that can provide complete packages of high-quality designs with a fluent functionality.</p>
    <p className={classes.ih41}> We aim to be the top choice for anyone seeking exceptional web Development in Mumbai and Mumbai, offering comprehensive packages that encompass high-quality, quality designs. Our commitment to excellence ensures every project is delivered with unmatched craftsmanship and attention to detail.</p>
</Fade>
</div>

</div>



<div className={classes.a1}>

<div  className={classes.a11}>
     <Fade left>
    <h3 className={classes.ih4}>Our Mission</h3>
    <p className={classes.ih41}>Our main goal is to make wonderful, feasible, and inventive websites that will exceed our client's assumptions.</p>
    <p className={classes.ih41}>We strive to blend creativity and functionality, ensuring each project reflects the unique vision and needs of our clients. Through meticulous attention to detail and a passion for innovation, we transform ideas into extraordinary environments.</p>
</Fade>
</div>
<div className={classes.a12}>
        <Fade right>
    <img src={a1} className={classes.img} />
    </Fade>
</div>

</div>



        </>
    )
}

export default Ab2;