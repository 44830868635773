import React from 'react'
import classes from "./Benifits.module.css"
import img1 from "./../../Assest/conversation.png"
import img2 from "./../../Assest/des.png"
import img3 from "./../../Assest/ux.png"
import img4 from "./../../Assest/opt.png"
import Fade from "react-reveal/Fade"

const Benifits = () => {
  return (
    <div>
<br/><br/>
    <h2 className={classes.ih3h}>Our Work Process</h2>
<div className={classes.pro1}>

<Fade left>
<div className={classes.pro}>
  <img src={img1} className={classes.img} /> 
  <h4 className={classes.h4}> Consulting & Planning </h4>
</div></Fade>
<Fade left>
<div className={classes.pro}>
  <img src={img2} className={classes.img} /> 
  <h4 className={classes.h4}> Design and Development </h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img3} className={classes.img} /> 
  <h4 className={classes.h4}> Content Integration and Testing</h4>
</div></Fade>
<Fade right>
<div className={classes.pro}>
  <img src={img4} className={classes.img} /> 
  <h4 className={classes.h4}> Launch and Maintenance </h4>
</div></Fade>




</div>



    </div>
  )
}

export default Benifits