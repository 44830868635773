import React from 'react'
import classes from "./box.module.css"
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade'

import zz2 from './../../Assest/o.png';
import zz12 from './../../Assest/c.png';
import zz25 from './../../Assest/h.png';


const box = () => {
  return (
    <div className={classes.steps}>

        <h2 className={classes.ih3h}>Our Services</h2>

<br/>

    


<div className={classes.steps1}> 
<Fade left >
<div className={classes.dbn}> <img src={zz2} className={classes.imgs} /><h5 className={classes.ih3}>Website Designe & Development</h5>  <button className={classes.b1}><Link to='/service'  className={classes.be}>Read More &nbsp; ›</Link></button> </div>
</Fade>
<Fade>
<div className={classes.dbn}> <img src={zz12} className={classes.imgs} /> <h5 className={classes.ih3}>Domain &<br></br> Hosting</h5> <button className={classes.b1}><Link to='/service'  className={classes.be}>Read More &nbsp; ›</Link></button> </div>
</Fade>
<Fade right >
<div className={classes.dbn}> <img src={zz25} className={classes.imgs} /> <h5 className={classes.ih3}>Content Management System</h5> <button className={classes.b1}><Link to='/service'className={classes.be}>Read More &nbsp; ›</Link></button> </div>
</Fade>

</div>

<br/> <br/>
<br/>




    </div>
  )
}

export default box


