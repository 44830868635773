import React from 'react'
import classes from "./Contact.module.css";
import Fade from 'react-reveal/Fade';

const Cnt = () => {
  return (
    <div className={classes.gblk}>
  <br /><br />
<h3 className={classes.GLLd}>Contact Us</h3>
<div className={classes.cn}>
<Fade left>   
<div className={classes.cn1}>

    <h5 className={classes.kke}>Address :</h5><br />

    {/* <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.694010857791!2d73.7942993724072!3d18.452197182628087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2950a58df56eb%3A0x1c8332e7a920b22b!2sAMBIONICS!5e0!3m2!1sen!2sin!4v1701341635893!5m2!1sen!2sin" className={classes.cnp}> SHED NO 3 S NO 134/1/2/1 DALVIWADI DHAYRI Mumbai NEAR NANDED PHATA BEHIND BIOMARK LABORATORY Mumbai 411041</a>

 */}  <a  className={classes.cnp}> Kandiwali East, Mumbai, 400101</a>

    <h5 className={classes.kke}>Email :</h5><br />

<a href="mailto:info@eshikatech.com" className={classes.cnp}>info@eshikatech.com</a><br />


<h5 className={classes.kke}>Phone :</h5><br />

<a href="tel:9967190248" className={classes.cnp}>+91 9967190248</a>
<br />
</div>
</Fade>


<Fade right>
    

<div className={classes.cn3}>
          <form className={classes.qo5} action="https://formsubmit.co/info@shikatech.com" method="POST">
                        <input placeholder='ENTER YOUR NAME' className={classes.I1} name='name' id='name' type="text"></input><br />
                        <input placeholder='ENTER MOBILE NO' maxLength={10} className={classes.I1} name='phn' id='phn' type="text"></input><br />
                        <input placeholder='ENTER YOUR EMAIL ID' className={classes.I1} name='mail' id='mail' type="email"></input><br />
                        <textarea placeholder='DESCRIPTION' rows="4" className={classes.t1} name='des' id='des'></textarea><br />
                        <input type="submit" value="SUBMIT" className={classes.b1} />
                    </form>
</div>



{/* <div className={classes.cn2}>


<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.694010857791!2d73.7942993724072!3d18.452197182628087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2950a58df56eb%3A0x1c8332e7a920b22b!2sAMBIONICS!5e0!3m2!1sen!2sin!4v1701341635893!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className={classes.srs}></iframe>
</div> */}
</Fade>
</div>
        
    </div>
  )
}

export default Cnt


                  

     


